.Width100 {
  width: 100%;
}

.TextAlignCenter {
  text-align: center;
}

.MarginLeft {
  margin-left: 6%;
}
.MarginTop {
  margin-top: 120px;
}

.MarginBottom {
  margin-bottom: 120px;
}

.PaddingTop {
  padding-top: 120px;
}

.PaddingBottom400 {
  padding-bottom: 400px;
}

.BorderSolid {
  border: solid 2px;
}

.BorderSolidRightLeftBottom {
  border-bottom: solid 2px;
  border-right: solid 2px;
  border-left: solid 2px;
}

.MarginTop36 {
  margin-top: 36px;
}

.MarginBottom36 {
  margin-top: 36px;
}

.HideNumberArrows input::-webkit-outer-spin-button,
.HideNumberArrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin            : 0;
}

.HideNumberArrows input[type=number] {
  -moz-appearance: textfield;
}