.typing-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.typing-indicator span {
  display: inline-block;
  width: 8px; /* Dot size */
  height: 8px;
  margin: 0 4px; /* Spacing between dots */
  background-color: #2196f3; /* Dot color */
  border-radius: 50%;
  opacity: 0;
  animation: typing 1.5s infinite ease-in-out; /* Adjust animation duration */
}

.typing-indicator span:nth-child(1) {
  animation-delay: 0s;
}
.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}
.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}
.typing-indicator span:nth-child(4) {
  animation-delay: 0.6s; /* Added delay for the 4th dot */
}
.typing-indicator span:nth-child(5) {
  animation-delay: 0.8s; /* Added delay for the 5th dot */
}

@keyframes typing {
  0%, 80%, 100% {
    opacity: 0;
    transform: scale(0.8); /* Scaling effect */
  }
  40% {
    opacity: 1;
    transform: scale(1.2); /* Dots grow slightly when visible */
  }
}
